/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/indigo-pink.css';
.success-snackbar {
    background: green;
    color: white;
    font-weight: 500;
}

.error-snackbar {
    background: red;
    color: white;
    font-weight: 500;
}

.success-snackbar .mat-simple-snackbar-action {
    color: white;
}

.error-snackbar .mat-simple-snackbar-action {
    color: white;
}

.disabled-div {
    pointer-events: none;
    opacity: 0.5;
    /* background: #CCC; */
}

.white-jumbotron {
    background-color: white;
}

.top-overlay {
    position: absolute;
    top: 200px;
    left: 45%;
}

.bottom-overlay {
    position: relative;
}

.mat-tab-header,
.mat-tab-nav-bar {
    border-bottom: 0;
}

.mat-tab-label .mat-tab-label-content {
    font-size: 1.5rem;
    font-weight: 700;
    color: black;
}

.mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #c4170c !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #c4170c;
}

.black-mat-spinner mat-progress-spinner circle {
    stroke: black;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #c4170c !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    color: #c4170c !important;
    background-color: #c4170c !important;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
    background-color: #c4170c !important;
}

#customTable {
    font-family: 'Trebuchet MS', Arial, Helvetica, sans-serif;
    border-collapse: collapse;
}

#customTable td,
#customTable th {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
}

#customTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customTable tr:hover {
    background-color: #ddd;
}

#customTable tr {
    height: 60px;
}

#customTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #f2f2f2;
    color: black;
    font-weight: 500;
}

cdk-virtual-scroll-viewport {
    width: 100%;
    height: 300px;
}

@media screen and (max-height: 1000px) {
    cdk-virtual-scroll-viewport {
        height: 500px;
    }
}

@media screen and (max-height: 800px) {
    cdk-virtual-scroll-viewport {
        height: 300px;
    }
}

.advanced-pie-legend .legend-items-container .legend-items {
    overflow: initial !important;
}

.advanced-pie-legend .total-value {
    font-size: 2rem !important;
}

.advanced-pie-legend .total-label {
    font-size: 1.3rem !important;
    margin-bottom: 10px !important;
    line-height: 1em !important;
    margin-left: 2px;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
    font-size: 1rem !important;
    margin-left: 11px;
    line-height: 1em !important;
    margin-top: 0px !important;
    margin-bottom: 5px;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-label {
    font-size: 0.75rem !important;
    opacity: 0.7;
    margin-left: 11px;
    margin-top: 0px !important;
    line-height: 1em !important;
    margin-bottom: 3px;
}

.advanced-pie-legend .legend-items-container .legend-items .legend-item .item-percent {
    font-size: 0.75rem !important;
    opacity: 0.7;
    margin-left: 11px;
    line-height: 1em !important;
}
